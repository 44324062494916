import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

export const Head = ({ location, data }) => {
  const siteMetaQuery = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          siteUrl
        }
      }
      contentfulAsset(title: { eq: "site-media-image" }) {
        gatsbyImageData
        description
      }
    }
  `);

  const { site, contentfulAsset } = siteMetaQuery;
  const { pathname } = location;
  const socialImagePath = getSrc(contentfulAsset);

  const generatePageTitle = () => {
    if (data.project) {
      return data.project.projectName;
    }
    if (pathname === "/my-writings") {
      return "My Other Projects";
    }
    if (pathname === "/") {
      return "Yolanda Bonnell's Digital Portfolio";
    }
    if (data.blogPost && data.blogPost.postTitle) {
      return data.blogPost.postTitle;
    }

    const { pageTitle } = Object.values(data)[0];
    return pageTitle;
  };

  return (
    <>
      <title>{generatePageTitle()}</title>
      <meta name="description" content={site.description} />

      {/* Open Graph / Facebook social share tags */}
      <meta property="og:title" content={generatePageTitle()} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={pathname ? `${site.siteUrl}${pathname}` : site.siteUrl}
      />
      <meta property="og:image" content={socialImagePath} />
      <meta
        property="og:image:alt"
        content="Yolanda Bonnell's portfolio website"
      />
      <meta property="og:description" content={site.description} />

      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={generatePageTitle()} />
      <meta name="twitter:description" content={site.description} />
      <meta name="twitter:image" content={socialImagePath} />
    </>
  );
};

export default Head;
