import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/Layout";
import RichText from "../components/RichText/RichText";
import PageDetails from "../components/PageDetails/PageDetails";

import {
  sectionWrapper,
  bannerImg,
  contentWrapper,
  pageDetailsStyles,
  whiteGirlsStyles,
} from "./project-page.module.scss";

const ProjectPage = ({ data }) => {
  const { project } = data;
  const { projectName, pageContent, pageImage } = project;

  const whiteGirlsPage = data.project.slug === "white-girls-in-moccasins";

  return (
    <Layout>
      {pageImage && (
        <GatsbyImage
          image={pageImage.image.gatsbyImageData}
          alt={pageImage.image.description}
          className={bannerImg}
        />
      )}
      <PageDetails heading={projectName} className={pageDetailsStyles} />
      <section className={`grid-wrapper ${sectionWrapper}`}>
        <article
          className={`${contentWrapper} grid-wrapper ${
            whiteGirlsPage ? whiteGirlsStyles : ""
          }`}
        >
          <RichText richText={pageContent} noWrapper />
        </article>
      </section>
    </Layout>
  );
};

export default ProjectPage;

export const projectQuery = graphql`
  query ($id: String!) {
    project: contentfulInternalProjects(id: { eq: $id }) {
      slug
      projectName
      pageContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData
            description
            __typename
          }
          ... on ContentfulHistoryBlock {
            contentful_id
            __typename
            blockContent {
              raw
            }
          }
        }
      }
      pageImage {
        image {
          gatsbyImageData
          description
        }
        caption {
          raw
        }
      }
    }
  }
`;

export { Head } from "../components/Head/Head";
