import React from "react";
import RichText from "../RichText/RichText";

import { sectionStyles, descriptionStyles } from "./PageDetails.module.scss";

const PageDetails = ({ heading, description, className }) => {
  return (
    <section className={`grid-wrapper ${sectionStyles} ${className}`}>
      <h1>{heading}</h1>
      {description && (
        <RichText className={descriptionStyles} richText={description} />
      )}
    </section>
  );
};

export default PageDetails;
